import { template as template_f847b8452b0542caabd305a67bdd8826 } from "@ember/template-compiler";
const FKLabel = template_f847b8452b0542caabd305a67bdd8826(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
